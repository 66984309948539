import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateTransPolicyInfo } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  getArrivalEstimatedNotice,
  getCurrentDawnDeliveryCutOffTime,
  getIsUnconditionalFreeShippingItem,
  getSmileDeliveryTransPolicy,
} from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

// 첫 노출시간
const SHOWING_HOURS_BEFORE_CUT_OFF = 5 // 시간 전부터 노출
// 타이머 붉은글씨 노출시간
const SHOWING_RED_HOURS_BEFORE_CUT_OFF = 1 // 시간 전부터 노출

const ArrivalEstimateContainer = ({
  itemNo,
}: {
  itemNo: string
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const arrivalEstimatedNotice = useSelector(
    (state: RootState) => getArrivalEstimatedNotice(state.shipping, itemNo),
    shallowEqual,
  )

  const smileDeliveryTransPolicy = useSelector((state: RootState) =>
    getSmileDeliveryTransPolicy(state.shipping, itemNo),
  )

  const isUnconditionalFreeShipping = useSelector((state: RootState) =>
    getIsUnconditionalFreeShippingItem(state.shipping, itemNo),
  )

  const currentTransPolicyDisplay = smileDeliveryTransPolicy?.transPolicyDisplay

  const isSmileDelivery = !!smileDeliveryTransPolicy

  const isDawnDeliveryItem = currentTransPolicyDisplay?.shippingType === 'Dawn'

  const [isOverCutOffTime, setOverCutOffTime] = useState(false)
  const [diff, setDiff] = useState(0)

  const dawnCutOffTime = useSelector((state: RootState) =>
    getCurrentDawnDeliveryCutOffTime(state.shipping),
  )

  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    if (dawnCutOffTime && isDawnDeliveryItem) {
      const cutOffMoment = moment(dawnCutOffTime)
      const startMoment = moment(dawnCutOffTime).subtract(
        SHOWING_HOURS_BEFORE_CUT_OFF,
        'hour',
      )
      if (
        cutOffMoment.isValid() &&
        startMoment.isBefore(moment()) &&
        cutOffMoment.isAfter(moment())
      ) {
        setOverCutOffTime(false)
        setDiff(cutOffMoment.diff(moment()))
        const intervalKey = window.setInterval(() => {
          setDiff(cutOffMoment.diff(moment()))
        }, 1000)

        const timeoutKey = window.setTimeout(() => {
          window.clearInterval(intervalKey)
          setOverCutOffTime(true)
        }, cutOffMoment.diff(moment()))

        return (): void => {
          window.clearInterval(intervalKey)
          window.clearTimeout(timeoutKey)
        }
      }
    }
  }, [dawnCutOffTime, isDawnDeliveryItem])

  const onClickRefresh = useCallback(() => {
    dispatch(updateTransPolicyInfo()).catch(() => {
      // DO NOTHING
    })
  }, [dispatch])

  if (!arrivalEstimatedNotice) {
    return <></>
  }

  return (
    <span className="box__delivery-info">
      {isSmileDelivery && (
        <span className="text__smiledelivery sprite__cart">
          <span className="for-a11y">스마일배송</span>
        </span>
      )}
      {isSmileDelivery && isUnconditionalFreeShipping && (
        <span className="text__freedelivery sprite__cart">
          <span className="for-a11y">무료배송</span>
        </span>
      )}
      {currentTransPolicyDisplay?.shippingType === 'Holiday' ? (
        <span className="text__delivery-make text__delivery-holiday sprite__cart--before">
          <span className="for-a11y">휴일배송</span>
          {arrivalEstimatedNotice.text}
        </span>
      ) : currentTransPolicyDisplay?.shippingType === 'Dawn' ? (
        <span
          className={`sprite__cart--before text__delivery-make text__delivery-smileclub ${
            isOverCutOffTime ? 'text__delivery-smileclub--timeover' : ''
          }`}
        >
          <span className="for-a11y">새벽배송</span>
          {arrivalEstimatedNotice.text}
          {isOverCutOffTime && (
            <button
              type="button"
              className="button__refresh sprite__cart--before"
              aria-live="polite"
              aria-label="상품 도착시간 새로고침"
              onClick={onClickRefresh}
            >
              새로고침
            </button>
          )}
        </span>
      ) : (
        <span className="text__delivery-make">
          {arrivalEstimatedNotice.text}
        </span>
      )}

      {isDawnDeliveryItem && isOverCutOffTime ? (
        <span className="text__condition text__condition--timeover">
          배송일정을 다시 확인해주세요.
        </span>
      ) : isDawnDeliveryItem && diff > 0 ? (
        <span
          className={`sprite__cart--before text__condition text__condition--${
            diff < 3600 * 1000 * SHOWING_RED_HOURS_BEFORE_CUT_OFF
              ? 'timeover'
              : 'timer'
          }`}
        >
          {moment
            .utc(diff)
            .format(diff < 3600 * 1000 ? 'm분 s초' : 'h시간 m분 s초') + ' 남음'}
        </span>
      ) : (
        arrivalEstimatedNotice.conditionText && (
          <span className="sprite__cart--before text__condition">
            {arrivalEstimatedNotice.conditionText}
          </span>
        )
      )}
    </span>
  )
}

export default ArrivalEstimateContainer
